import { template as template_d8e7f83de4a847a58ebc4f89a41621ad } from "@ember/template-compiler";
const WelcomeHeader = template_d8e7f83de4a847a58ebc4f89a41621ad(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
