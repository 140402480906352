import { template as template_80aa913e417e48a9a92d281bb71c9e81 } from "@ember/template-compiler";
const FKLabel = template_80aa913e417e48a9a92d281bb71c9e81(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
